import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import { useSleapStep } from "@/utils/customHooks";
import { useNavigate } from "react-router-dom";
import { getHomeUrl } from "@/utils";
import { AppContext, ShowSideStepDetail, useTeam } from "@/context";

export default function SideStepNavigation(): JSX.Element {
    const { dispatch } = React.useContext(AppContext);
    const [sleapStep, setSleapStep] = useSleapStep();
    const navigate = useNavigate();
    const team = useTeam();
    const sleapSteps = [
        {
        link: '',
        char: 'S',
        disabled: false,
    }, {
        link: '',
        char: 'L',
        disabled: false,
    }, {
        link: '',
        char: 'E',
        disabled: false,
    }, {
        link: '',
        char: 'A',
        disabled: false,
    }, {
        link: '',
        char: 'P',
        disabled: false,
    },
];

    const currentIndex = React.useMemo(() => sleapSteps.findIndex(step => step.char === sleapStep), [sleapStep]);

    const handleStepClick = React.useCallback((action: {char: string, link: string, disabled: boolean}) => {

        if (action.disabled) return;

        if (action.char === 'A') {
            navigate(getHomeUrl(team) + `/measure`, { state: 'A' });
            setSleapStep('A');
            dispatch({ type: ShowSideStepDetail, value: true });
        } else if (action.char === 'P') {
            navigate(getHomeUrl(team) + `/measure`, { state: 'P' });
            setSleapStep('P');
            dispatch({ type: ShowSideStepDetail, value: true });
        } else if (action.char === 'E') {
            location.pathname.split('/').pop() === 'sleap' && navigate(getHomeUrl(team) + `/understand/sleap`);
            dispatch({ type: ShowSideStepDetail, value: true });
            setSleapStep(action.char);
        } 
        else {
            location.pathname.split('/').pop() === 'sleap' && navigate(getHomeUrl(team) + `/understand/sleap`);
            dispatch({ type: ShowSideStepDetail, value: false });
            setSleapStep(action.char);
        }
    }, [sleapStep]);

    React.useEffect(() => {

       if (sleapStep.length > 0) {
           if (sleapStep !== 'A' && sleapStep !== 'P') {
               location.pathname.split('/').pop() !== 'sleap' && navigate(getHomeUrl(team) + `/understand/sleap`);
           }
       } else {

           //navigate(getHomeUrl(team) + `/understand`);
       }
    }, [sleapStep]);

    return (
        <div style={{...styles.sideStep, }}>
            {sleapSteps.map((action, i) => (
                <div
                    key={i}
                    onClick={() => handleStepClick(action)}
                    style={{
                        ...style.centerFlex,
                        ...(action.disabled) && {cursor: 'default', opacity: 0.5},
                        ...(i !== currentIndex && !action.disabled) && { cursor: 'pointer' },
                    }}
                >
                    {i === currentIndex && (<img src={require(`../assets/navigation/side/active.png`)} alt={''} />)}
                    {i > currentIndex && (<img src={require(`../assets/navigation/side/default.png`)} alt={''} />)}
                    {i < currentIndex && (<img src={require(`../assets/navigation/side/inactive.png`)} alt={''} />)}
                    <span style={{
                        ...styles.char,
                        ...(i !== currentIndex && { opacity: 0.5 }),
                        ...(i === currentIndex && { color: colors.darkBlue }),
                    }}>
                        {action.char}
                    </span>
                </div>
            ))}
        </div>
    );
}

const styles = StyleSheet.create({
    sideStep: {
        position: 'absolute',
        right: 20,
        top: 125,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        gap: 8,
    },
    char: {
        color: 'white',
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
    },
});
