import React from "react";
import { colors, StyleSheet } from "@/utils/style";
import PriorityTriangle from "@/project/sleap/Assess/PriorityTriangle";
import { Icon } from "@/lib";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import useFetchDependencies  from "@/utils/useFetchDependencies";
import useFetchImpacts from "@/utils/useFetchImpacts";
import Bubble from "@/lib/Bubble";
import DetailContent from "@/project/understand/DetailContent";
import { AssessTileProps, INumberBoxProps } from "@/types/sleap";
import { getBubbleBarColor } from "@/utils/getBubbleBarColor";

const AssessDetailRow: React.FC<AssessTileProps> = ({ site, isOpen, openHandler }) => {
    //console.log('site from assessDetailRow =====>>>> ', site);
    const economicActivitiesIds = site.economic_activities.map(ea => ea.economic_activity_id).join(',');

    const {
            totalUniqueImpacts,
            totalUniqueImpactsWithRating,
            restructuredImpactsGeoServer,
            loading,
            error,
          } = useFetchImpacts(economicActivitiesIds);

        const {
        totalUniqueDependencies,
        totalUniqueDependenciesWithRating,
        restructuredDependenciesGeoServer,
    } = useFetchDependencies(economicActivitiesIds);

   
    const economicActivities: number = React.useMemo(()=> {

        return site.economic_activities.length;

    }, [site.economic_activities]);


    const address: string = React.useMemo(() => {

        // if (site.address && typeof site.address === "string") {

        //     return site.address;
        // }
        // return '';
        return !site.street_address || !site.city || !site.zip_code ? "-" : site.street_address + ',' + site.city + ',' + site.zip_code;
    }, [site.street_address]);

    return (
        <>
            <tr style={{ ...(isOpen ? styles.rowIsOpen : styles.rowIsClosed) }}>
              <td>{site.site_name}</td>
              {/* <td>{site.site_key}</td> */}
              <td>{address}</td>
              <td>{economicActivities}</td>
              <td>
                  <NumberBox count={totalUniqueImpactsWithRating} total={12} />
              </td>
              <td>
                  <NumberBox count={totalUniqueDependenciesWithRating} total={21} />
              </td>
              <td>
                <PriorityTriangle value={site.priority_score} />
              </td>
              <td>
                <Icon
                    style={{ ...styles.chevron }}
                    color="white"
                    path={isOpen ? mdiChevronUp : mdiChevronDown}
                    size={32}
                    onClick={() => isOpen ? openHandler(null) : openHandler(site.site_id)}
                />
              </td>
            </tr>
            {isOpen &&

                <tr style={{ ...styles.detailRow }}>
                    <td colSpan={8}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>ECONOMIC<br />ACTIVITIES</div>
                                    <div>
                                        <Bubble
                                            count={economicActivities}
                                            size='md'
                                            style={{ backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]), color: colors.white }}
                                        />
                                    </div>
                                </div>
                                <div style={{ paddingTop: 30 }}>
                                    <ul style={styles.list}>
                                        {site.economic_activities.length > 0 && site.economic_activities.map(value => (
                                            <li key={value.id}>{value.economic_activity_name.replaceAll('_', ' ')}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid #FFFFFF', paddingLeft: 20, paddingRight: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>IMPACTS</div>
                                    <div>
                                        <Bubble
                                            count={totalUniqueImpactsWithRating}
                                            countTotal={12}
                                            size='md'
                                            style={{ backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]), color: colors.white }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <DetailContent
                                        barColor={colors.white}
                                        backgroundColor={colors.red}
                                        color={'white'}
                                        clusteredRatings={restructuredImpactsGeoServer}
                                        totalAvailable={12}
                                        hideCloseButton={true}
                                    />
                                </div>
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid #FFFFFF', paddingLeft: 20, paddingRight: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>DEPENDENCIES</div>
                                    <div>
                                        <Bubble
                                            count={totalUniqueDependenciesWithRating}
                                            countTotal={21}
                                            size='md'
                                            style={{ backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]), color: colors.white}}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <DetailContent
                                        barColor={colors.white}
                                        backgroundColor={colors.yellow}
                                        color={'black'}
                                        clusteredRatings={restructuredDependenciesGeoServer}
                                        totalAvailable={21}
                                        hideCloseButton={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            }
        </>

    );
   
};
const stylesRow = StyleSheet.create({
    row: {
        fontSize: 12,
        font: 'normal normal bold 28px/10px Arial',
    },
    
});

const NumberBox: React.FC<INumberBoxProps> = ({ count, total }) => {

    return (
        <div style={{
            ...stylesNumberBox.numberBox,
        }}>
            <div style={{ ...stylesNumberBox.count }}>{count ? count : '0'}</div>
            {total && (<div style={{ ...stylesNumberBox.total }}>out of {total}</div>)}
        </div>
    );
};

const stylesNumberBox = StyleSheet.create({
    numberBox: {
        fontSize: 12,
        font: 'normal normal bold 28px/10px Arial',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    total: {
        fontSize: 8,
        marginTop: -5,
    },
});



const styles = StyleSheet.create({
    chevron: {
        cursor: 'pointer',
    },
    rowIsClosed: {
        backgroundColor: colors.mediumBlue,
    },
    rowIsOpen: {
        backgroundColor: colors.opaqueBlue,
        borderBottom: '0px',
    },
    detailRow: {
        backgroundColor: colors.opaqueBlue,
    },
    list: {
        padding: 'unset',
        margin: 'unset',
    },
});

export default AssessDetailRow;
