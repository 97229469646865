import * as React from 'react';
import { AppContext, NavigationChanged, ProjectExploreRastersChanged, SideStepVisible, useTeam } from '@/context';
import { getHomeUrl } from '@/utils';
import NavButton from '@/lib/MainNavigation/NavButton';
import Logo from '@/lib/Logo';
import { NavLink, useLocation } from 'react-router-dom';
import "./MainNavigation.scss";

const AvatarThreshold = 30;


export default function MainNavigation(): JSX.Element {
    const { state, dispatch } = React.useContext(AppContext);
    const [isAvatarVisible, _setIsAvatarVisible] = React.useState<boolean>(window.scrollY <= AvatarThreshold);
    const isVisibleRef = React.useRef(isAvatarVisible);
    const [currentIndex, setCurrentIndex] = React.useState(1);
    const setIsAvatarVisible = (visible: boolean) => {
        isVisibleRef.current = visible;
        _setIsAvatarVisible(visible);
    };
    const team = useTeam();

    React.useEffect(() => {
        const track = () => {
            const visible = window.scrollY <= AvatarThreshold;

            if (visible !== isVisibleRef.current) {
                setIsAvatarVisible(visible);
            }
        };

        window.addEventListener('scroll', track);

        return () => window.removeEventListener('scroll', track);
    }, []);

    if (!state.navigationEnabled) {
        return <span />;
    }

    const isSuperUser = state.config?.user.is_superuser;

    const routes = [
        {
            title: 'Explore',
            url: 'explore',
            sideStep: false,
        },
        {
            title: 'Analyse',
            url: 'understand',
            sideStep: true,
        },
        // Commented to be a referrence.
        // {
        //     title: 'Manage',
        //     url: 'measure',
        //     sideStep: false,
        // },
        {
            title: 'Download',
            url: 'risk',
            sideStep: false,
        },

    ];

    // if (isSuperUser) {
    //     routes.push({
    //         title: 'Risk',
    //         url: 'risk',
    //         sideStep: false,
    //     });
    // }

    function handleNavClick(i: number) {
        setCurrentIndex(i);
        dispatch({ type: ProjectExploreRastersChanged, rasters: {} })
        dispatch({ type: NavigationChanged, sideStepActive: '' });
    }

    const location = useLocation();
    React.useEffect(() => {

        setCurrentIndex(routes.findIndex(r => r.url === location.pathname.split('/').pop()));
    }, [location.pathname]);

    const showSideStepNavigation = routes[currentIndex]?.sideStep || location.pathname.split('/').pop() === 'sleap';

    React.useEffect(() => {

        dispatch({ type: SideStepVisible, showSideStep: showSideStepNavigation });
        !showSideStepNavigation && dispatch({ type: NavigationChanged, sideStepActive: '' });
    }, [showSideStepNavigation]);

    return (
        <div className="header">
            <div className="logo">
                <Logo />
            </div>
            {team && (<div className="nav-button-bar">
                {routes.map((route, i) => (
                    <NavButton
                        key={i}
                        clickHandler={(ind: number) => handleNavClick(i)}
                        i={i}
                        title={route.title}
                        route={getHomeUrl(team) + `/${route.url}`}
                        currentIndex={currentIndex}
                    />
                ))}
            </div>)}
            <div className="nav-info-bar">
                {team && (<NavLink to="/account">
                    <img src={require('../assets/buttons/profile-btn.png')} />
                </NavLink>)}
            </div>
        </div>
    );
}
