import { colors, style, StyleSheet } from "@/utils/style";
import React from "react";
import { useSites } from "@/utils/customHooks";
import {SiteInfoTabProp} from "@/types/sleap";
// import CryptoJS from "crypto-js";
// import {useTeam} from "@/context";


export const PrioritySitesInfo: React.FC<SiteInfoTabProp> = ({ onFilter }) => {
  const { sites } = useSites();
  const [activeOptionId, setActiveOptionId] = React.useState(3);
  // const team = useTeam();
  // let totalSites = sites.length;
  // let prioritySites = sites.filter(site => site.priority_location).length;
  const [totalSites, setTotalSites] = React.useState(0);
  const [prioritySites, setPrioritySites] = React.useState(0);
  // console.log("sites from PrioritySitesInfo",sites);
  // const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
  //   const header = {
  //       headers: {
  //           'Authorization': `Bearer ${encryptedToken}`
  //       }
  //   };
  //   async function getPriorityCount(){
        
  //     let countEndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/sites/count-priority-locations?company_id=${team.id}`;
  //     const response = await fetch(countEndPointURL,header);
  //     const priorityCount = await response.json();
  //     return priorityCount.count;
  // }
  // getPriorityCount().then((result)=>{
  //   setPrioritySites(result);
  // });
  // async function getSitesCount(){
      
  //     let countEndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/sites/count-sites?company_id=${team.id}`;
  //     const response = await fetch(countEndPointURL,header);
  //     const sitesCount = await response.json();
  //     return sitesCount.count;
  // }
  // getSitesCount().then((result)=>{
  //   setTotalSites(result);
  // });
  const options = React.useMemo(() => ([
    {
      id: 1,
      label: 'Priority',
      filter: () => (sites.filter(site => site.priority_location)),
    }, {
      id: 2,
      label: 'Non Priority',
      filter: () => (sites.filter(site => !site.priority_location)),
    }, {
      id: 3,
      label: 'All',
      filter: () => (sites),
    },
  ]), [sites]);

  React.useEffect(() => {

    const queryMap:Record<number, string[]> = {
      1: ['priority_location=True'],
      2: ['priority_location=False'],
    };

    const queries = queryMap[activeOptionId] || [];

    onFilter(queries);

  }, [activeOptionId]);

  React.useEffect(() => {

    setTimeout(() => {
      if (localStorage.getItem('totalSites')) {
        const totalSites = JSON.parse(localStorage.getItem('totalSites'));
        console.log('totalSites from localstorage ===>>>> ', totalSites);
        // setImpacts(impacts);
        setTotalSites(totalSites);
      }
  
      if (localStorage.getItem('prioritySites')) {
        const prioritySites = JSON.parse(localStorage.getItem('prioritySites'));
        console.log('prioritySites from localstorage ===>>>> ', prioritySites);
        // setImpacts(impacts);
        setPrioritySites(prioritySites);
      }
    }, 300);

   

  }, []);

  return (
    <>
      <div style={{
        ...style.bubble, ...style.centerFlex, ...style.upperCaseCenterText,
        backgroundColor: colors.purple,
        position: 'relative',
      }}>
        {prioritySites}
        <span style={style.totalSpan}>out of {totalSites}</span>
      </div>
      <div style={{ flex: 1, padding: '0 30px', height: '100%' }}>
        <p>Show me: </p>
        <div style={{ ...style.flexRow }}>
          {options.map(option =>
            <button
              style={{ ...styles.button, ...(activeOptionId === option.id && { border: `3px solid ${colors.purple}` }) }}
              key={option.id}
              onClick={() => setActiveOptionId(option.id)}
            >
              {option.label}
            </button>,
          )}
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
    width: 120,
    borderRadius: 20,
    height: 30,
    border: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
  },
});
