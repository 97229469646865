import React, { useContext } from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import { LocationSearch } from "@/project/sleap/Locate/LocationSearch";
import AssessTile from "@/project/sleap/Assess/AssessTile";
import { Button } from "@/project/sleap/Button";
import { AssessmentInfoBox } from "@/project/sleap/Assess/InfoBox/AssessmentInfoBox";
import { SleapContext } from "@/context/SleapContext";
import AssessDetailRow from "@/project/sleap/Assess/AssessDetailRow";
import { AppContext, ToastAdded, useProject, useTeam } from "@/context";
import { CheckHandler, IAssessDetailTable } from "@/types/sleap";
import { AssessmentDetailHeader } from "@/project/sleap/Assess/AssessmentDetailHeader";
import { ExportButton } from "@/project/sleap/Assess/ExportButton";
import { apiGet, t } from "@/utils";
import Pagination from "@/lib/Pagination/Pagination";
import { accumulatePriorityScore } from "@/utils/calculatePriorityScore";
import { SortOrder } from '@/utils/sort';
import CryptoJS from "crypto-js";

let oldDbSites= [];
const maxSitesOnPage = 10;
const Assess: React.FC<CheckHandler> = ({ checkHandler }) => {
    const { state } = useContext(AppContext);
    const [sites, setSites] = React.useState<INewSite[]>([]);
    const [currentOpen, setCurrentOpen] = React.useState<number>();
    const [filterInput, setFilterInput] = React.useState<string>('');
    const { sleapState, sleapDispatch } = React.useContext(SleapContext);
    const [page, setPage] = React.useState<number>(1);
    const [sitesCount, setSitesCount] = React.useState<number>(-1);
    const [filterQueries, setFilterQueries] = React.useState<string[]>([]);
    const project = useProject();
    const team = useTeam();
    const { dispatch } = useContext(AppContext);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>('desc');
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            'Authorization': `Bearer ${encryptedToken}`
        }
    };


    React.useEffect(() => {

        checkHandler(sites.length > 0);
    }, [sites.length]);


    const getSites = () => {
      let NewDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?page=${page}&company_id=${team.id}`
      let NewDbUrlQueries = [...filterQueries];
      const queries = ['limit=10', ...filterQueries];
     
        queries.push('order_by=priority_score');
        queries.push('order_direction=' + sortOrder);

        NewDbUrlQueries.push('sortBy=priority_score');
        NewDbUrlQueries.push('sortOrder=' + sortOrder);
        NewDbUrlQueries.push('pageSize=10');


      let currentPage = page;
      if (filterInput.length) {
        currentPage = 1;
        queries.push('filter=' + filterInput);
        NewDbUrlQueries.push('search_all=' + filterInput);
      }
      const url = `projects/${project.slug}/reports/${project.latest_report_id}/site/page/${currentPage}`;
      apiGet<{count: number, items: INewSite[], pages: number, current_page: number}>(team.slug, url.concat(queries.length ? '?' + queries.join('&') : '')).then(reply => {
        if (reply.ok) {
          setSitesCount(reply.data.count);
          // const sitesWithCalculatedScore = reply.data.items.map((site) => {
          //   const impactScore = accumulatePriorityScore(site.impacts);
          //   const dependencyScore = accumulatePriorityScore(site.dependencies);
          //   return {
          //     ...site,
          //     calculatedPriorityScore: impactScore + dependencyScore
          //   };
          // });
          // setSites(sitesWithCalculatedScore);
          oldDbSites = reply.data.items;
        }
        else {
          dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.failed_to_load_sites') } });
        }
      }).finally((async () => {
        try {
            NewDbUrl = NewDbUrl + '&' + NewDbUrlQueries.join('&');
            console.log(NewDbUrl);
            const response = await fetch(NewDbUrl,header);
            const data = await response.json();
            const mappedSites =  await data.sites;
            setSites(mappedSites);
            //setSitesCount(mappedSites.length);
            console.log('mapped sites ===>>> ', mappedSites);
        } catch (error) {
            console.error('Error fetching sites from kuyuaappdb:', error);
            dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.failed_to_load_sites') } });
        }
    }));
    };

    React.useEffect(() => {
      getSites();
    }, [page, filterInput, filterQueries, sortOrder]);

    React.useEffect(() => {

      return () => {
        sleapDispatch({ filteredSites: undefined });
      };
    }, []);

    React.useEffect(() => {

      if (sleapState?.selectedSiteId) {

        const siteSelectedElement = document.querySelector(`#assess-${sleapState.selectedSiteId}`);
        if (siteSelectedElement) {
          siteSelectedElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
      setCurrentOpen(sleapState?.selectedSiteId ?? 0);

    }, [sleapState?.selectedSiteId]);

    const filterSites = (queries: string[]) => {

      setFilterQueries(queries);
    };
    function handleSortingType(){
      setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    }
    const url = `/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/site/export/csv`
    return (
      <>
        <div style={{ ...style.flexColumn, padding: 2 }}>
            {!state.showSideStepDetail && (
              <div style={{ ...style.flexRow, height: 50, padding: 10, gap: 10 }}>
                <LocationSearch inputHandler={setFilterInput} />
                <ExportButton url={url} />
              </div>
            )}

            {state.showSideStepDetail && <AssessDetailTable
              sites={sites}
              openHandler={setCurrentOpen}
              currentOpen={currentOpen}
              inputHandler={setFilterInput}
              onFilterSites={filterSites}
              onChangePage={(page: number) => setPage(page)}
              page={page}
              sitesCount={sitesCount}
              handleSorting={handleSortingType}
            />
            }

            {!state.showSideStepDetail &&
                <>
                    <div style={{ ...style.flexColumn, gap: 2, height: 300, overflowY: 'scroll', justifyContent: 'flex-start' }}>
                        {sites.map(site => {
                                return (
                                    <AssessTile
                                        key={site.id + '_' + site.site_id}
                                        site={site}
                                        openHandler={setCurrentOpen}
                                        isOpen={currentOpen == site.id}
                                    />
                                );
                            },
                        )}
                    </div>
                    {sitesCount > maxSitesOnPage &&
                      <Pagination
                        changePage={setPage}
                        currentPage={page}
                        lastPage={Math.ceil(sitesCount / maxSitesOnPage)}
                      />
                    }
                    {/* <div style={{ ...style.centerFlex, gap: 10, flexDirection: 'row', justifyContent: 'flex-start', padding: '10px 20px' }}>
                            <Button title="NEXT STEP: PREPARE" style={styles.button} disabled={true} />
                    </div> */}
                </>
            }
        </div>
        {/* {!state.showSideStepDetail && <AssessmentInfoBox onFilter={filterSites} /> } */}
      </>
    );
};

const AssessDetailTable: React.FC<IAssessDetailTable> = ({ sites, openHandler, currentOpen, inputHandler, onFilterSites, onChangePage, page, sitesCount, handleSorting }) => {
    const [filterOpen, setFilterOpen] = React.useState(false);

    const handleFilterToggle = () => {

      if (filterOpen) {
        onFilterSites([]);
      }
      setFilterOpen(prev => !prev);
    };



    return (
      <div style={style.sleapBackDrop}>
        <AssessmentDetailHeader inputHandler={inputHandler} toggleFilter={handleFilterToggle} filterOpen={filterOpen} />
        <div style={styles.tableWrapper}>
          {filterOpen && <AssessmentInfoBox onFilter={onFilterSites} unsetPosition={true} /> }
          <div className="assess-table">
            <table>
              <thead>
              <tr>
                <th>SITE NAME</th>
                {/* <th>SITE ID</th> */}
                <th>ADDRESS</th>
                <th>ECONOMIC ACTIVITIES</th>
                <th>IMPACTS</th>
                <th>DEPENDENCIES</th>
                <th onClick={() => handleSorting()}>PRIO</th>
                <th></th>
              </tr>
              </thead>
              <tbody style={{ overflowY: 'scroll' }}>
              {
                sites.map(site => {

                  return (
                    <AssessDetailRow
                      key={site.site_id}
                      site={site}
                      openHandler={openHandler}
                      isOpen={currentOpen == site.site_id}
                    />
                  );
                })
              }
              </tbody>
            </table>

            {sitesCount > maxSitesOnPage &&
              <Pagination
                changePage={onChangePage}
                currentPage={page}
                lastPage={Math.ceil(sitesCount / maxSitesOnPage)}
              />
            }
          </div>
        </div>
      </div>
    );
};

const styles = StyleSheet.create({
    button: {
        position: 'fixed',
        right: '25%',
        bottom: 100,
    },
    headerBar: {
        backgroundColor: colors.mediumBlue,
        height: 60,
        width: '100%',
        color: 'white',
        padding: '20px 15px',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
    },
    tableWrapper: {
      display: 'flex',
      flex: 1,
      maxHeight: '70vH',
      padding: 10,
      overflow: 'hidden',
      flexDirection: 'column',
      alignItems: 'center',
    },
});

export default Assess;
