import { colors, style } from "@/utils/style";
import React from "react";
import Checkbox from "@/utils/Checkbox";
import { apiGet } from "@/utils";
import { url } from "@/rest/apiHelper";
import { AppContext, useProject, useTeam } from "@/context";
import Dropdown from "@/utils/Dropdown";
// import { initImpactDependencyClusters } from "@/lib/AppLoader";
import { DropdownOption, SiteInfoTabProp } from "@/types/sleap";

export const DependencySitesInfo: React.FC<SiteInfoTabProp> = ({ onFilter }) => {
  const project = useProject();

  const allDependencies = { id: -1, label: 'ALL' };

  const [selectedScores, setSelectedScores] = React.useState<string[]>([]);
  const [numberDependencies, setNumberDependencies] = React.useState<number>(0);
  const [applicableDependencies, setApplicableDependencies] = React.useState<number>(0);
  const [totalUniqueDependenciesWithRating, setTotalUniqueDependenciesWithRating] = React.useState<number>(0);
  const [dependencies, setDependencies] = React.useState<DropdownOption[]>([]);
  const [selectedDependencies, setSelectedDependencies] = React.useState<DropdownOption[]>([allDependencies]);
  const { state, dispatch } = React.useContext(AppContext);
  const team = useTeam();

  const [scoreOptions, setScoreOptions] = React.useState<{ label: string, checked: boolean }[]>([
    { label: 'VH', checked: false },
    { label: 'H', checked: false },
    { label: 'M', checked: false },
  ]);

  React.useEffect(() => {

    // fetchDependencies();
    // get number impacts from local storage
    setTimeout(() => {
      if (localStorage.getItem('totalUniqueDependenciesWithRating')) {
        const _totalUniqueDependenciesWithRating = JSON.parse(localStorage.getItem('totalUniqueDependenciesWithRating'));
        console.log('totalUniqueDependenciesWithRating from localstorage ===>>>> ', _totalUniqueDependenciesWithRating);
        // setImpacts(impacts);
        setTotalUniqueDependenciesWithRating(_totalUniqueDependenciesWithRating);
      }
    }, 300);
    
  }, []);

//   React.useEffect(() => {

//     if (!state?.dependencyClusters?.length) {

//       initImpactDependencyClusters(dispatch, team.slug, project.slug);
//     }
//  else {

//       let dependencyRatings:iCluster[] = [];
//       dependencyRatings = state?.dependencyClusters.slice(0, 3);
//       const impacts = dependencyRatings.reduce((sum, impact) => sum + impact.values.length, 0);
//       setApplicableDependencies(impacts);
//     }
//   }, [state?.dependencyClusters]);

  React.useEffect(() => {

    setSelectedScores(scoreOptions.filter(score => score.checked).map(score => score.label));
  }, [scoreOptions]);

  React.useEffect(() => {

    const queries = [];
    if (!selectedDependencies.some(dep => dep.id === -1)) {

      queries.push(`dependency_ids[]=${selectedDependencies.map(dep => dep.id).join("&dependency_ids[]=")}`);
    }
    if (selectedScores.length) {

      queries.push(`dependency_ratings[]=${selectedScores.join("&dependency_ratings[]=")}`);
    }
    onFilter(queries);

  }, [selectedScores, selectedDependencies]);

//   function fetchDependencies() {

//     apiGet<string[]>(null, url.dependencies).then((reply) => {
//       if (reply.ok) {
//         const dependenciesFromDb = reply.data.map((dep, i) => (
//           { id: i, label: dep.replaceAll('_', ' ').toUpperCase() }
//         ));
//         dependenciesFromDb.unshift(allDependencies);
//         setDependencies(dependenciesFromDb);
//         setNumberDependencies(reply.data.length);
//       }
//  else {
//         throw new Error(`${reply.status}`);
//       }
//     });
//   }

  const handleCheck = (i: number, check: boolean) => {

    setScoreOptions(prev => {
      const newArray = prev.slice();
      newArray.splice(i, 1, {
        ...prev[i],
        checked: check,
      });
      return newArray;
    });
  };

  const handleSelect = (selected: number[]) => {

    if (!selected.length || selected[selected.length - 1] === -1) {

      setSelectedDependencies([dependencies.find(dep => dep.id === -1)]);
    }
 else {

      let newSelected: DropdownOption[] = selected.map(select => dependencies.find(dep => dep.id === select));
      if (selected[0] === -1) {
        newSelected = newSelected.slice(1);
      }
      setSelectedDependencies(newSelected);
    }
  };


  return (
    <>
      <div style={{
        ...style.bubble, ...style.centerFlex, ...style.upperCaseCenterText,
        backgroundColor: colors.yellow,
        color: colors.black,
        position: 'relative',
      }}>
        {totalUniqueDependenciesWithRating}
        <span style={style.totalSpan}>out of 21</span>
      </div>
      <div style={{ flex: 1, padding: '0 30px', height: '100%', ...style.flexRow, alignItems: 'flex-start', gap: 20 }}>
        <div style={{ flex: 1 }}>
          <Dropdown
            label={'Show me:'}
            options={dependencies}
            selected={selectedDependencies.map(s => s.id)}
            multiple={true}
            handler={handleSelect}
            labelStyle={{ fontSize: 16 }}
            listDropdownStyle={{ maxHeight: 100 }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <span>Rating: </span>
          <div style={{ ...style.flexRow, alignItems: 'center' }}>
            {scoreOptions.map((score, i) => (
              <Checkbox
                checked={score.checked}
                label={score.label}
                key={i}
                checkHandler={(check:boolean) => handleCheck(i, check)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
